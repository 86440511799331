<template>
  <div class="cases">
    <Header />
    <CasesContent :cases="cases" />
    <FormSection/>
    <Footer/>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Header from "@/components/Header";
import CasesContent from "@/components/CasesContent";
import FormSection from "@/components/FormSection";
import Footer from "@/components/Footer";

export default {
  name: "Cases",
  components: {
    Header,
    CasesContent,
    FormSection,
    Footer
  },
  computed: {
    ...mapGetters({
      cases: "getCases"
    })
  },
  metaInfo: {
    title: 'Our Cases - WakeApp',
    meta: [{
      vmid: 'description',
      name: 'description',
      content:"Here You Can Find Our Cases and Study Them ✅ 8+ Years Expertise ✅ 300+ Professional Who Work for Result ✅ More Than 500 000 FTD Per Year",
    }],
  }
}
</script>
