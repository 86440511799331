<template>
  <div class="Case">
    <div class="Case-Icons">
      <img
          :src="require(`@/assets/images/${caseInfo.logo}`)"
          alt="logo"
          class="Case-Icon"
      >
    </div>
    <div class="Case-Content">
      <div class="Case-ContentHeader">
        <div class="Case-ContentFlags">
          <div v-if="isMobile" class="Case-ContentTitle">
            {{ caseInfo.info.title }}
          </div>
          <img v-for="(flag, $flag) in caseInfo.info.flags"
              :key="'B' + $flag"
              :src="require(`@/assets/images/${flag}`)"
              alt="logo"
              class="Case-ContentFlag"
          >
          <div class="Case-ContentFlagsInfo" v-if="caseInfo.info.flagsText">{{ caseInfo.info.flagsText }}</div>
        </div>
        <div v-if="!isMobile" class="Case-ContentTitle">
          {{ caseInfo.info.title }}
        </div>
      </div>

      <div class="Case-ContentSlider" v-if="caseInfo.logos.length">
        <swiper
          :options="options"
          class="CaseSlider"
        >
          <swiper-slide
            v-for="(icon, $icon) in caseInfo.logos"
            :key="$icon"
            class="CaseSlider-Slide"
          >
            <img
              :src="require(`@/assets/images/${icon}`)"
              alt="icons"
            >
          </swiper-slide>
        </swiper>        
      </div>

      <div class="Case-ContentInfos">
        <div
            v-for="(info, $info) in caseInfo.info.info"
            :key="'C'+$info"
            class="Case-ContentInfo"
        >
          <div class="Case-ContentInfoRank">
            {{ info.rank }}
          </div>
          <p
              class="Case-ContentInfoText"
              v-html="info.text"
          ></p>
        </div>
      </div>
      <img
          v-if="caseInfo.info.stars"
          :src="require(`@/assets/images/${caseInfo.info.stars}`)"
          alt="stars"
          class="Case-ContentStars"
      >
      <p
          v-for="(review, $review) in caseInfo.info.review"
          :key="$review"
          class="Case-ContentReview"
          v-html="review"
      ></p>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Case",
  props: {
    caseInfo: {
      type: Object,
      default: () => {
      },
    },
  },
  data() {
    return {
      options: {
        slidesPerView: 'auto',
        slidesPerGroup: 3,
        spaceBetween: 10,
        speed: 700,
      }      
    }
  },
  computed: {
    ...mapGetters({
      isMobile: 'isMobile'
    })
  }
}
</script>

<style lang="scss">
.Case {
  width: 100%;
  max-width: 100%;
  margin-bottom: .2vw;
  background-image: linear-gradient(0deg, rgba(255, 82, 82, 0.15) -11.99%, rgba(255, 82, 82, 0) 100%);

  &:nth-child(2n-1) {
    background-image: linear-gradient(0deg, rgba(177, 154, 206, 0.15) -11.99%, rgba(255, 82, 82, 0) 100%);
  }

  @include s-s {
    display: flex;
    justify-content: center;
    background-image: none !important;
    background-color: var(--color-body);
  }

  @include s-xxl {
    margin-bottom: 2px;
  }

  &:first-child {
    .Case-Content {
      padding-top: 13.341vw;

      @include s-xxl {
        padding-top: 189px;
      }

      .mobile &,
      .landscape &,
      .portrait & {
        padding-top: 13.341vw;
        // padding-top: 189px;
      }
    }

    .Case-Icons {
      &:after {
        top: 53%;

        .mobile &,
        .landscape &,
        .portrait & {
          top: 60%;
        }
      }
    }

    .Case-Icon {
      top: 54%;

      .mobile &,
      .landscape &,
      .portrait & {
        top: 64%;
      }
    }
  }

  &:last-child {
    margin-bottom: 0;
  }

  &-Icons {
    display: none;

    @include s-s {
      position: relative;
      width: 34%;
      padding: 3.9vw;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      background: linear-gradient(-90deg, rgba(177, 154, 206, 0.6) -11.99%, rgba(255, 82, 82, 0) 100%);
  
      :nth-child(2n-1) > & {
        background: linear-gradient(-90deg, rgba(255, 82, 82, 0.6) -11.99%, rgba(255, 82, 82, 0) 100%);
      }
  
      &:after {
        content: "";
        position: absolute;
        top: 53%;
        right: -1px;
        transform: translate(0, -50%);
        width: 2vw;
        height: 1.5vw;
        border-top: 2.155vw solid transparent;
        border-bottom: 2.155vw solid transparent;
        border-right: 2.31vw solid var(--color-body);
  
        @include s-xxl {
          width: 28px;
          height: 21px;
          border-width: 30px 32px 30px;
        }
      }
    }

    @include s-xxl {
      padding: 45px;
    }
  }

  &-Icon {
  }

  &-Content {
    padding: 5.14vw 5.61vw 5.14vw 10.28vw;
    
    @include s-s {
      padding: 3.386vw 0 1.847vw 5.541vw;
      width: 66%;
    }

    @include s-xxl {
      padding: 48px 0 26px 78px;
    }
  }

  &-ContentHeader {
    margin-bottom: 1.642vw;

    @include s-max-s {
      display: flex;
      align-items: center;
      margin-bottom: 4.21vw;
    }
  }

  &-ContentFlags {
    display: flex;
    align-items: center;
  }

  &-ContentFlag {
    width: 4.91vw;
    margin-right: 2.34vw;

    @include s-max-s {
      position: relative;
      top: -2px;
    }

    @include s-s {
      width: 1.928vw;
      margin-right: 1.744vw;
    }

    @include s-xxl {
      width: 27px;
      margin-right: 24px;
    }
  }

  &-ContentTitle {
    font-weight: 500;
    font-size: 4.21vw;
    text-transform: uppercase;
    color: var(--color-text-main2);
    
    @include s-s {
      margin-bottom: 1.642vw;
      font-size: 3.592vw;
      font-weight: 300;
    }
    
    @include s-xxl {
      margin-bottom: 23px;
      font-size: 51px;
    }
  }

  &-ContentSlider {
    @include s-s {
      display: none;
    }

    @include s-max-s {
      overflow: hidden;
      margin: 0 -5.61vw 0 -10.28vw;
      padding-bottom: 36px;
    }

    img {
      vertical-align: middle;
      box-shadow: 0px 18.3474px 30.579px rgba(163, 56, 222, 0.15);
      border-radius: 17px;
    }
  }

  .CaseSlider {
    &-Slide {
      width: 18.69vw;
    }

    margin: 0 5.61vw 0 10.28vw;
  }

  &-ContentInfos {
    display: flex;
    align-items: flex-start;
    margin-top: -18px;
  }

  &-ContentInfo {
    display: flex;
    flex-direction: column;
    margin-right: 12.5vw;
    font-size: 4.21vw;
    line-height: 1.16;
    color: var(--color-text-main1);

    @include s-s {
      font-size: 1.563vw;
    }

    @include s-xxl {
      margin-right: 177px;
      font-size: 22px;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  &-ContentInfoRank {
    font-weight: 700;
  }

  &-ContentStars {
    width: 71px;
    margin-bottom: .462vw;
    margin-top: 2.155vw;

    @include s-xxl {
      width: 96px;
      margin-top: 30px;
      margin-bottom: 6px;
    }
  }

  &-ContentReview {
    font-family: 'MullerItalic', sans-serif, monospace;
    font-weight: 300;
    font-size: 12px;
    line-height: 1.15;
    color: var(--color-text-review);

    @include s-s {
      font-size: 1.283vw;
    }

    @include s-xxl {
      font-size: 18px;
    }
  }
}
</style>
