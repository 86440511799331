<template>
  <section class="CasesContent">
    <div class="CasesContent-Cases Wrapper">
      <Case
          v-for="(info, $info) in cases"
          :key="$info"
          :case-info="info"
      />
    </div>
  </section>
</template>

<script>
import Case from "@/components/Case";

export default {
  name: "CasesContent",
  props: {
    cases: {
      type: Array,
      default: new Array,
    },
  },  
  components: {Case}
}
</script>

<style lang="scss">
.CasesContent {
  transition: opacity 0.5s ease-in-out;

  &:not(&--ready) {
    opacity: 0;
  }

  &-Cases {
    margin-top: -11vw;
    padding-right: 0;
    padding-left: 0;

    @include s-xxl {
      margin-top: -156px;
    }
  }
}
</style>
